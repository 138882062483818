import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _755c34a6 = () => interopDefault(import('../sites/nobilissk/pages/articleList/index.vue' /* webpackChunkName: "sites/nobilissk/pages/articleList/index" */))
const _28f16278 = () => interopDefault(import('../sites/nobilissk/pages/ean/index.vue' /* webpackChunkName: "sites/nobilissk/pages/ean/index" */))
const _7782f88c = () => interopDefault(import('../sites/nobilissk/pages/search/index.vue' /* webpackChunkName: "sites/nobilissk/pages/search/index" */))
const _2f687e92 = () => interopDefault(import('../sites/nobilissk/pages/contact/index.vue' /* webpackChunkName: "sites/nobilissk/pages/contact/index" */))
const _4ce5246a = () => interopDefault(import('../sites/nobilissk/pages/checkout/index.vue' /* webpackChunkName: "sites/nobilissk/pages/checkout/index" */))
const _ce8f24f0 = () => interopDefault(import('../sites/nobilissk/pages/faq/index.vue' /* webpackChunkName: "sites/nobilissk/pages/faq/index" */))
const _395742b4 = () => interopDefault(import('../sites/nobilissk/pages/shoppingGuide/index.vue' /* webpackChunkName: "sites/nobilissk/pages/shoppingGuide/index" */))
const _39b7b050 = () => interopDefault(import('../sites/nobilissk/pages/login.vue' /* webpackChunkName: "sites/nobilissk/pages/login" */))
const _52eb8806 = () => interopDefault(import('../sites/nobilissk/pages/branch/index.vue' /* webpackChunkName: "sites/nobilissk/pages/branch/index" */))
const _6ac9c204 = () => interopDefault(import('../sites/nobilissk/pages/register.vue' /* webpackChunkName: "sites/nobilissk/pages/register" */))
const _36afcf63 = () => interopDefault(import('../sites/nobilissk/pages/passwordReset.vue' /* webpackChunkName: "sites/nobilissk/pages/passwordReset" */))
const _2324eea6 = () => interopDefault(import('../sites/nobilissk/pages/sitemapconfig.vue' /* webpackChunkName: "sites/nobilissk/pages/sitemapconfig" */))
const _74bbe942 = () => interopDefault(import('../sites/nobilissk/pages/maintenance.vue' /* webpackChunkName: "sites/nobilissk/pages/maintenance" */))
const _caf965a2 = () => interopDefault(import('../sites/nobilissk/pages/user/index.vue' /* webpackChunkName: "sites/nobilissk/pages/user/index" */))
const _503f5360 = () => interopDefault(import('../sites/nobilissk/pages/aboutPurchase/index.vue' /* webpackChunkName: "sites/nobilissk/pages/aboutPurchase/index" */))
const _21d55cae = () => interopDefault(import('../sites/nobilissk/pages/passwordForgotten.vue' /* webpackChunkName: "sites/nobilissk/pages/passwordForgotten" */))
const _6969ce46 = () => interopDefault(import('../sites/nobilissk/pages/checkout/info.vue' /* webpackChunkName: "sites/nobilissk/pages/checkout/info" */))
const _10a6a80a = () => interopDefault(import('../sites/nobilissk/pages/checkout/finished.vue' /* webpackChunkName: "sites/nobilissk/pages/checkout/finished" */))
const _60d9574a = () => interopDefault(import('../sites/nobilissk/pages/checkout/methods.vue' /* webpackChunkName: "sites/nobilissk/pages/checkout/methods" */))
const _3c320c84 = () => interopDefault(import('../sites/nobilissk/pages/checkout/importOrder/index.vue' /* webpackChunkName: "sites/nobilissk/pages/checkout/importOrder/index" */))
const _44c2cbed = () => interopDefault(import('../sites/nobilissk/pages/user/shoppingLists/index.vue' /* webpackChunkName: "sites/nobilissk/pages/user/shoppingLists/index" */))
const _44d0654a = () => interopDefault(import('../sites/nobilissk/pages/user/orders/index.vue' /* webpackChunkName: "sites/nobilissk/pages/user/orders/index" */))
const _36786f54 = () => interopDefault(import('../sites/nobilissk/pages/user/settings.vue' /* webpackChunkName: "sites/nobilissk/pages/user/settings" */))
const _9ea9191e = () => interopDefault(import('../sites/nobilissk/pages/user/dashboard.vue' /* webpackChunkName: "sites/nobilissk/pages/user/dashboard" */))
const _32926a64 = () => interopDefault(import('../sites/nobilissk/pages/user/password.vue' /* webpackChunkName: "sites/nobilissk/pages/user/password" */))
const _2e1ef34f = () => interopDefault(import('../sites/nobilissk/pages/user/addressList.vue' /* webpackChunkName: "sites/nobilissk/pages/user/addressList" */))
const _08c7dfc6 = () => interopDefault(import('../sites/nobilissk/pages/checkout/payment/callback.vue' /* webpackChunkName: "sites/nobilissk/pages/checkout/payment/callback" */))
const _197c84c1 = () => interopDefault(import('../sites/nobilissk/pages/index.vue' /* webpackChunkName: "sites/nobilissk/pages/index" */))
const _407ce595 = () => interopDefault(import('../sites/nobilissk/pages/user/shoppingLists/_id.vue' /* webpackChunkName: "sites/nobilissk/pages/user/shoppingLists/_id" */))
const _27df6a83 = () => interopDefault(import('../sites/nobilissk/pages/user/orders/_id.vue' /* webpackChunkName: "sites/nobilissk/pages/user/orders/_id" */))
const _008ef2a0 = () => interopDefault(import('../sites/nobilissk/pages/article/_slug.vue' /* webpackChunkName: "sites/nobilissk/pages/article/_slug" */))
const _73a5935e = () => interopDefault(import('../sites/nobilissk/pages/articleList/_slug.vue' /* webpackChunkName: "sites/nobilissk/pages/articleList/_slug" */))
const _47400576 = () => interopDefault(import('../sites/nobilissk/pages/landingPageList/index.vue' /* webpackChunkName: "sites/nobilissk/pages/landingPageList/index" */))
const _5134e6be = () => interopDefault(import('../sites/nobilissk/pages/branch/_slug.vue' /* webpackChunkName: "sites/nobilissk/pages/branch/_slug" */))
const _6d79e14e = () => interopDefault(import('../sites/nobilissk/pages/product/_slug.vue' /* webpackChunkName: "sites/nobilissk/pages/product/_slug" */))
const _2bf104aa = () => interopDefault(import('../sites/nobilissk/pages/page/_slug.vue' /* webpackChunkName: "sites/nobilissk/pages/page/_slug" */))
const _d874dd64 = () => interopDefault(import('../sites/nobilissk/pages/_.vue' /* webpackChunkName: "sites/nobilissk/pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/clanky",
    component: _755c34a6,
    name: "articleList___sk"
  }, {
    path: "/ean",
    component: _28f16278,
    name: "ean___sk"
  }, {
    path: "/hladat",
    component: _7782f88c,
    name: "search___sk"
  }, {
    path: "/kontakt",
    component: _2f687e92,
    name: "contact___sk"
  }, {
    path: "/kosik",
    component: _4ce5246a,
    name: "checkout___sk"
  }, {
    path: "/najcastejsie-dotazy",
    component: _ce8f24f0,
    name: "faq___sk"
  }, {
    path: "/poradna",
    component: _395742b4,
    name: "shoppingGuide___sk"
  }, {
    path: "/prihlasit",
    component: _39b7b050,
    name: "login___sk"
  }, {
    path: "/prodejny",
    component: _52eb8806,
    name: "branch___sk"
  }, {
    path: "/registrovat",
    component: _6ac9c204,
    name: "register___sk"
  }, {
    path: "/resetovat-heslo",
    component: _36afcf63,
    name: "passwordReset___sk"
  }, {
    path: "/sitemapconfig",
    component: _2324eea6,
    name: "sitemapconfig___sk"
  }, {
    path: "/udrzba",
    component: _74bbe942,
    name: "maintenance___sk"
  }, {
    path: "/uzivatel",
    component: _caf965a2,
    name: "user___sk"
  }, {
    path: "/vsetko-o-nakupe",
    component: _503f5360,
    name: "aboutPurchase___sk"
  }, {
    path: "/zabudnute-heslo",
    component: _21d55cae,
    name: "passwordForgotten___sk"
  }, {
    path: "/kosik/dodacie-udaje",
    component: _6969ce46,
    name: "checkout-info___sk"
  }, {
    path: "/kosik/dokoncenie",
    component: _10a6a80a,
    name: "checkout-finished___sk"
  }, {
    path: "/kosik/doprava-a-platba",
    component: _60d9574a,
    name: "checkout-methods___sk"
  }, {
    path: "/kosik/import-objednavky",
    component: _3c320c84,
    name: "checkout-importOrder___sk"
  }, {
    path: "/uzivatel/nakupne-zoznamy",
    component: _44c2cbed,
    name: "user-shoppingLists___sk"
  }, {
    path: "/uzivatel/objednavky",
    component: _44d0654a,
    name: "user-orders___sk"
  }, {
    path: "/uzivatel/osobni-udaje",
    component: _36786f54,
    name: "user-settings___sk"
  }, {
    path: "/uzivatel/prehled",
    component: _9ea9191e,
    name: "user-dashboard___sk"
  }, {
    path: "/uzivatel/zmena-hesla",
    component: _32926a64,
    name: "user-password___sk"
  }, {
    path: "/uzivatel/zoznam-adries",
    component: _2e1ef34f,
    name: "user-addressList___sk"
  }, {
    path: "/checkout/payment/callback",
    component: _08c7dfc6,
    name: "checkout-payment-callback___sk"
  }, {
    path: "/",
    component: _197c84c1,
    name: "index___sk"
  }, {
    path: "/uzivatel/nakupne-zoznamy/:id",
    component: _407ce595,
    name: "user-shoppingLists-id___sk"
  }, {
    path: "/uzivatel/objednavky/:id",
    component: _27df6a83,
    name: "user-orders-id___sk"
  }, {
    path: "/clanky/:slug",
    component: _008ef2a0,
    name: "article-slug___sk"
  }, {
    path: "/clanky/:slug",
    component: _73a5935e,
    name: "articleList-slug___sk"
  }, {
    path: "/navigacia/:slug",
    component: _47400576,
    name: "landingPageList___sk"
  }, {
    path: "/prodejny/:slug",
    component: _5134e6be,
    name: "branch-slug___sk"
  }, {
    path: "/produkt/:slug",
    component: _6d79e14e,
    name: "product-slug___sk"
  }, {
    path: "/stranka/:slug",
    component: _2bf104aa,
    name: "page-slug___sk"
  }, {
    path: "/*",
    component: _d874dd64,
    name: "all___sk"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
